/**
 * Stylesheet:  style.css
 * Theme:       Corporate - Business & Corporate HTML5 Responsive Template
 * Version:     1.0
 * Author:     	Theme Path
 * URL:         https://themeforest.net/user/themepath
 * 
 */
/* Table of contents
=========================
1.Preloader
2.Scrollup
3.Header Section
4.Menu  Section
5.Slider Section
6.Title Section
7.Newsletter Section
8.About Section
9.Service Section
10.Service Page 
11.Count Up Section 
12.Why Choose Us Section
13.Project Section
14.Call To Action Section
15.Pricing Section
16.FAQ Section
17.Blog Section
18.Sidebar Section
19.Testimonial Section
20.Page Heading Section
21.Team section
22.About Page
23.Contact Page
24.Footer Section 
===========================*/
/*------------------*/
/*Theme default CSS
/*------------------*/
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none; }

html,
body {
  height: 100%;
  font-size: 16px;
  color: #666666;
  font-family: 'Source Sans Pro', sans-serif;
  vertical-align: baseline;
  line-height: 26px; }

a:focus {
  outline: 0px solid; }

img {
  max-width: 100%;
  height: auto; }

p {
  margin: 0 0 20px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  color: #464646; }

h1 {
  font-size: 32px; }

h2 {
  font-size: 22px; }

h3 {
  font-size: 18px; }

a {
  -moz-transition: all 0.5s ease-out;
       transition: all 0.5s ease-out;
  text-decoration: none;
  color: #464646; }

a:active,
a:hover {
  outline: 0 none;
  color: #212836;
  text-decoration: none; }

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

::-moz-selection {
  background: #1fa7ec;
  text-shadow: none;
  color: #fff; }

::selection {
  background: #1fa7ec;
  text-shadow: none;
  color: #fff; }

textarea:focus, input:focus {
  outline: none; }

.slider-overlay {
  background-color: rgba(31, 167, 236, 0.8);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1; }

.images-overlay {
  background-color: rgba(70, 70, 70, 0.7);
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0; }

.pt-50 {
  padding-top: 50px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-50 {
  padding-bottom: 50px; }

.bg-color {
  background: #ecf4fc; }

.slider {
  overflow: hidden; }

/*
1.Preloader
============================*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999999;
  right: 0;
  bottom: 0; }

#preloader-status {
  height: 210px;
  width: 210px;
  background: url("../img/preloader.gif");
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
  position: absolute;
  left: 50%;
  top: 50%; }

/*
2.Scrollup
==============*/
#scrollUp {
  background: #1fa7ec none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #fff;
  font-size: 25px;
  height: 40px;
  right: 20px;
  text-align: center;
  text-decoration: none;
  width: 40px; }

#scrollUp:hover {
  opacity: 1;
  color: #fff !important;
  background: #00b9f2; }

#scrollUp i {
  display: block;
  font-weight: bold;
  padding-top: 6px; }

/*
3.Header Section
==================*/
.hd-sec {
  background: #1fa7ec none repeat scroll 0 0;
  padding: 10px 0; }

.hd-lft li {
  color: #fff;
  display: inline-block;
  padding: 0 15px;
  position: relative;
  font-size: 14px; }

.hd-lft li:first-child:before {
  content: ""; }

.hd-lft li::before {
  content: "|";
  position: absolute;
  left: 0; }

.hd-lft li i {
  margin-right: 10px;
  color: #fff; }

.hd-rgt {
  float: right; }

.hd-rgt li {
  display: inline-block;
  position: relative; }

.hd-rgt li a {
  color: #fff;
  font-size: 14px;
  padding: 0 10px;
  position: relative;
  text-transform: capitalize; }

.hd-rgt li a i {
  margin-right: 8px;
  color: #fff; }

/*
4.Menu  Section
=================*/
.logo {
  padding: 28px 0px; }

.main-menu ul {
  float: right;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  position: relative; }

.main-menu ul li {
  display: inline-block;
  padding: 0;
  position: relative; }

.main-menu ul li a {
  color: #464646;
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 33px 20px;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s;
  position: relative; }

.main-menu ul li.active a {
  color: #1fa7ec;
  position: relative; }

.main-menu ul li ul:hover .main-menu ul li a {
  background: #ddd; }

.main-menu ul li a:hover {
  -moz-transition: all 0.4s 0s ease;
       transition: all 0.4s 0s ease;
  color: #1fa7ec; }

.main-menu ul ul {
  background: #212836 none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  padding: 10px;
  position: absolute;
  text-align: left;
  top: 92px;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s;
  visibility: hidden;
  z-index: 99999;
  -moz-transform: scaleY(0);
   -ms-transform: scaleY(0);
       transform: scaleY(0);
  -moz-transform-origin: 0 0 0;
   -ms-transform-origin: 0 0 0;
       transform-origin: 0 0 0;
  -moz-transition: all 0.3s ease 0s;
       transition: all 0.3s ease 0s; }

.main-menu ul ul li {
  float: none;
  width: 200px; }

.main-menu ul ul li a {
  line-height: 120%;
  padding: 10px 5px;
  color: #fff; }

.main-menu ul ul li a:hover {
  color: #fff; }

.main-menu ul ul ul {
  top: 0;
  left: 100%; }

.main-menu ul li:hover ul {
  visibility: visible;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s;
  opacity: 1;
  -moz-transform: scaleY(1);
   -ms-transform: scaleY(1);
       transform: scaleY(1); }

.main-menu ul li a i {
  margin-left: 6px; }

/*
5.Slider Section
======================*/
.all-slide .owl-item {
  height: 500px; }

.all-slide .single-slide {
  background-size: 100% 100%;
  /*background-position: center center;*/
  background-repeat: no-repeat;
  height: 100%;
  /*min-height: 700px;*/ }

.slider-text {
  left: 0;
  margin: 0 auto;
  padding: 0px 290px;
  text-align: center;
  position: relative;
  right: 0;
  text-align: center;
  top: 50%;
  -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
       transform: translateY(-50%);
  z-index: 2; }

.slider-text h2 {
  color: #fff;
  text-transform: uppercase; }

.slider-text h1 {
  color: #fff;
  font-size: 32px;
  text-transform: uppercase; }

.slider-text p {
  color: #fff; }

.slider-text li {
  display: inline-block; }

.slider-text li a {
  background: #212836 none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  padding: 12px 30px;
  text-transform: uppercase;
  margin: 0px 10px; }

.slider-text li a:hover {
  background: #212836 !important;
  color: #fff;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.slider-text li:last-child a {
  background: #ff7b00 none repeat scroll 0 0; }

.all-slide .owl-controls {
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 99;
  -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
       transform: translateY(-50%); }

.all-slide .owl-controls .owl-buttons div {
  background: none;
  opacity: 1.00; }

.all-slide .owl-controls .owl-prev {
  left: -100px;
  position: absolute;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.all-slide:hover .owl-prev {
  left: 0px;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.all-slide .owl-controls .owl-next {
  right: -100px;
  position: absolute;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.all-slide:hover .owl-next {
  right: 0px;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.all-slide .owl-controls .owl-prev i,
.all-slide .owl-controls .owl-next i {
  background: #fff none repeat scroll 0 0;
  border-radius: 2px;
  color: #1fa7ec;
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  width: 40px; }

.all-slide .owl-controls .owl-next i:hover {
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  background: #212836;
  color: #fff; }

.all-slide .owl-controls .owl-prev i:hover {
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  background: #212836;
  color: #fff; }

/*
6.Title Section
=================*/
.sec-title {
  margin: 0 250px 60px;
  text-align: center; }

.sec-title h1 {
  font-size: 36px;
  text-transform: uppercase;
  color: #1fa7ec;
  font-weight: bold; }

.sec-title h1 span {
  font-weight: normal; }

.border-shape::before {
  background: #1fa7ec none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 1px;
  left: 80px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 100px; }

.border-shape {
  background: #1fa7ec none repeat scroll 0 0;
  color: #fff;
  display: block;
  height: 3px;
  left: 0;
  margin: 20px auto;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  width: 80px; }

.border-shape::after {
  background: #1fa7ec none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 1px;
  right: 80px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 100px; }

.sec-title p {
  margin-bottom: 0; }

/*
7.Newsletter Section
=======================*/
.newsletter-sec {
  background: #1fa7ec none repeat scroll 0 0;
  padding: 50px 0; }

.newsletter-left h1 {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase; }

.newsletter-left p {
  color: #ffffff;
  margin-bottom: 0px; }

.newsletter-right input[type="email"] {
  background: #fafafa none repeat scroll 0 0;
  border: 1px solid #fafafa;
  border-radius: 3px;
  padding: 10px 20px;
  color: #a5a5a5;
  text-transform: capitalize; }

.newsletter-right input[type="submit"] {
  background: #ff7b00;
  border: 1px solid #ff7b00;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px; }

.newsletter-right input[type="submit"]:hover {
  background: #fff;
  border-color: #fff;
  color: #464646; }

.newsletter-right {
  float: right;
  margin-top: 8px; }

/*
8.About Section
===================*/
.about-sec .sec-title {
  margin-bottom: 20px; }

.about {
  padding-bottom: 50px;
  padding-top: 50px; }

.about-sec img {
  width: 100%; }

.about-sec h3 {
  text-transform: uppercase; }

.about-text p {
  margin-bottom: 15px; }

.about-text {
  display: table-cell;
  vertical-align: middle; }

.intro-text i {
  color: #1fa7ec;
  float: left;
  margin-top: 6px; }

.intro-text p {
  padding-left: 30px; }

.about-us-tab {
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-bottom: 24px; }

.about-us-tab li {
  display: inline-block; }

.about-us-tab .active a {
  background: #1fa7ec;
  color: #fff; }

.about-us-tab a {
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 16px;
  padding: 10px 36px;
  text-transform: uppercase;
  background: #212836;
  border-radius: 2px;
  margin: 0px 2px; }

/*
9.Service Section
============================*/
.img-service {
  background-image: url("../img/home/servico.jpg"); }

.service {
  /*background-image: url("../img/home/servico.jpg");*/
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed; }

.service-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%; }

.service i,
.why-choose i,
.service i {
  background: #1fa7ec none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px; }

.why-choose .inner:hover i {
  background: #212836 none repeat scroll 0 0; }

.service-item .inner {
  padding: 15px; }

.service-item .inner:hover {
  background: #1fa7ec;
  color: #fff !important;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s; }

.service-item .inner:hover i {
  background: #fff;
  -moz-transition: all 0.4s ease 0s;
       transition: all 0.4s ease 0s;
  color: #1fa7ec; }

.service-item .inner {
  margin-bottom: 30px;
  text-align: center;
  border-radius: 2px; }

.service-item .inner:hover.inner h2 a {
  color: #fff !important; }

.service-item .inner:hover.inner p {
  color: #fff !important; }

.service-item .inner h2,
.why-choose h2 {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 15px; }

.btn.rdmorebtn {
  background: #1fa7ec none repeat scroll 0 0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase; }

.btn.rdmorebtn:hover {
  background: #212836;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

/*
10.Service Page 
============================*/
.service.service-sec {
  background: #fff none repeat scroll 0 0; }

.service.service-sec .service-item .inner h2 a {
  color: #464646; }

.service.service-sec .service-item .inner p {
  color: #666; }

/*
11.Count Up Section 
============================*/
.count-up-sec {
  padding: 50px 0;
  background: #1fa7ec; }

.count-up-sec .sec-title h1 {
  color: #fff; }

.count-up-sec .sec-title p {
  color: #fff; }

.counting_sl {
  text-align: center; }

.counting_sl i {
  background: #fff none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 60px;
  color: #1fa7ec; }

.counting_sl h2 {
  color: #fff;
  font-size: 40px;
  margin-top: 20px;
  text-transform: uppercase; }

.counting_sl h4 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase; }

/*
12.Why Choose Us Section
============================*/
.project-sec .item {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  border-radius: 2px; }

.project-sec .item:hover .project-text h3 {
  background: #212836 none repeat scroll 0 0;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.project-sec .item:hover .prject-thumb a {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
   -ms-transform: scale(1);
       transform: scale(1); }

.prject-thumb a i:hover {
  background: #212836 none repeat scroll 0 0;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.project-hoverlay {
  background: #1fa7ec none repeat scroll 0 0;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  width: 100%;
  bottom: 0; }

.prject-thumb {
  position: relative; }

.prject-thumb a i {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #1fa7ec;
  height: 50px;
  left: 0;
  line-height: 50px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
       transform: translateY(-50%);
  width: 50px; }

.prject-thumb a {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(31, 167, 236, 0.7);
  visibility: hidden;
  opacity: 0;
  -moz-transform: scale(5);
   -ms-transform: scale(5);
       transform: scale(5); }

.project-text {
  text-align: center; }

.project-text a {
  color: #fff; }

.project-text h3 {
  color: #fff;
  margin-bottom: 0;
  padding: 12px 0;
  text-transform: uppercase;
  font-size: 16px; }

.project-sec .item img {
  width: 100%; }

.why-choose img {
  width: 100%; }

/*
13.Project Section
============================*/
.why-choose {
  background: #ecf4fc; }

.why-choose .inner {
  margin-bottom: 30px;
  text-align: center; }

/*
14.Call To Action Section
============================*/
.bg-intel {
  background-image: url("../img/home/bg-intel.jpg"); }

.bg-contatenos {
  background-image: url("../img/sobre/sobre-contato.jpg"); }

.call-to-action-sec {
  background-color: #fff;
  /*background-image: url("../img/home/bg-intel.jpg");*/
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  position: relative; }

.call-to-action-overlay {
  background-color: rgba(70, 70, 70, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.call-to-action-text {
  text-align: left; }

.call-to-action-text {
  color: #fff; }

.call-to-action-text p {
  margin-bottom: 0; }

.call-to-action-text h2 {
  color: #fff;
  text-transform: uppercase; }

.call-to-action-text a.btn {
  background: #ff7b00 none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  float: right;
  font-weight: bold;
  padding: 12px 36px;
  text-transform: uppercase;
  margin-top: 10px; }

.call-to-action-text a.btn:hover {
  background: #212836;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

/*
15.Pricing Section
===================*/
.pricing-sec {
  background-attachment: fixed;
  background-image: url("../img/servicebg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }

.pricing-overlay {
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%; }

.pricing-sec .brder-btm {
  border-color: #fff; }

.sngl-price {
  background: #1fa7ec none repeat scroll 0 0;
  margin-bottom: 30px;
  padding-bottom: 36px;
  text-align: center;
  border-radius: 2px; }

.sngl-price h2 {
  color: #1fa7ec;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800; }

.price-heading {
  background: #242424;
  padding: 26px 0; }

.price-heading h2 {
  color: #fff;
  display: inline-block;
  font-size: 22px;
  margin-bottom: 15px; }

.pakage-icon {
  text-align: center;
  padding: 20px 0; }

.pakage-icon i {
  border-radius: 2px;
  color: #fff;
  font-size: 40px;
  height: 80px;
  line-height: 80px;
  width: 80px; }

.sngl-price h1 {
  color: #fff;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: bold;
  text-transform: capitalize; }

.sngl-price h1 span {
  font-size: 18px;
  color: #656766; }

.sngl-price ul li {
  display: inline-block;
  padding: 10px 0;
  width: 100%; }

.sngl-price ul li {
  display: inline-block;
  margin: 0;
  color: #fff; }

.snglPrice-lrnMore {
  margin-top: 30px; }

.btn.price_btn {
  background: #1fa7ec none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  padding: 10px 20px;
  text-transform: uppercase; }

.btn.price_btn:hover {
  background: #212836;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.sngl-price:hover .btn.price_btn {
  background: #212836;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.sngl-price:hover .pakage-icon i {
  background: #212836;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

/*
16.FAQ Section
============================*/
.faq-sec {
  background-color: #2a2d33;
  padding: 50px 0;
  color: #fff; }

.faq-title {
  margin-bottom: 40px; }

.faq-sec .panel {
  background: transparent; }

.faq-sec .panel-content {
  padding: 15px; }

.faq-title > h2 {
  color: #fff;
  text-transform: uppercase; }

.faq-img {
  display: inline-block;
  width: 100%; }

.faq-img img {
  width: 100%; }

.faq-sec .panel-heading {
  background: #454c59;
  padding: 0;
  border-radius: 2px; }

.faq-sec .panel-heading a {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 2px;
  color: #fff; }

.faq-sec .panel-title a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f056";
  padding-right: 5px; }

.faq-sec .panel-title a.collapsed:before {
  float: right !important;
  content: "\f055"; }

.faq-sec .panel-title > a:hover,
.faq-sec .panel-title > a:active,
.faq-sec .panel-title > a:focus {
  text-decoration: none;
  background: #1fa7ec; }

/*
17.Blog Section
==================*/
.blog-sec .blog-post {
  padding: 0; }

.single-post {
  display: inline-block;
  margin-bottom: 50px; }

.blog-title h2 {
  color: #0f0f0f;
  font-size: 24px;
  font-weight: 900;
  line-height: 30px; }

.single-post-text a {
  color: #464646;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase; }

.pst_info {
  float: left;
  display: inline-block; }

.post-date h2 {
  background: #1fa7ec;
  text-align: center;
  padding: 10px 5px;
  font-size: 18px;
  color: #fff; }

.blog-title {
  padding-bottom: 20px; }

.single-post img {
  border-bottom: 4px solid #1fa7ec;
  border-radius: 2px;
  margin-bottom: 14px;
  width: 100%; }

.comment-list {
  border: 1px solid #ddd;
  font-size: 18px;
  padding: 14px;
  text-align: center; }

.comment-list h5 {
  margin-bottom: 0; }

.single-post-text h2 {
  margin-bottom: 10px; }

.single-post-text ul {
  display: inline-block;
  width: 100%; }

.single-post-text li a {
  float: left;
  font-size: 15px;
  font-weight: normal;
  margin-left: 1px;
  padding-right: 15px;
  text-transform: capitalize; }

.single-post-text li a i {
  margin-right: 5px;
  color: #1fa7ec; }

.post-date h1 {
  background: #f3af1e none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  margin-top: 13px;
  padding: 15px;
  text-align: center;
  text-transform: none; }

.commentar-sec img {
  width: 80px;
  float: left;
  margin-right: 20px; }

.comment-title {
  margin-bottom: 10px; }

.commentar-sec .media.mt-3 {
  margin-top: 30px; }

.single-post .contact-field h2 {
  margin-bottom: 0;
  font-size: 18px;
  margin-top: 40px; }

.commentar-sec h2 {
  margin-top: 50px;
  text-transform: uppercase; }

/*
18.Sidebar Section
=====================*/
.sidebar h1 {
  color: #1fa7ec;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: uppercase;
  width: 100%; }

.search-field form {
  display: inline-block;
  width: 100%;
  position: relative; }

.search-field input[type=text] {
  background: #1fa7ec none repeat scroll 0 0;
  color: #fff;
  padding: 10px 35px 10px 10px;
  width: 100%; }

.search-field button {
  background: #1fa7ec none repeat scroll 0 0;
  color: #fff;
  position: absolute;
  padding: 10px;
  right: 0; }

.blog-sec .inner:nth-child(2n+1) {
  clear: both; }

.abt_dec > p {
  margin-top: 20px; }

.sidebar ul li a {
  color: #717171;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.sidebar ul li a:hover {
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  color: #1fa7ec; }

.widget-archive li {
  position: relative; }

.widget-archive li:last-child {
  border-bottom: none; }

.widget-cat li:last-child {
  border-bottom: none; }

.widget-cat li {
  position: relative; }

.widget-cat li {
  padding: 10px 0;
  border-bottom: 1px solid #ececec; }

.widget-tag li a:hover {
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  background: #1fa7ec;
  color: #fff !important;
  border-color: #1fa7ec; }

.relative-post h3 {
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 10px; }

.relative-post {
  border-bottom: 1px solid #ececec;
  display: inline-block;
  padding: 12px 0;
  width: 100%; }

.relative-post-thumb {
  float: left;
  margin-right: 15px; }

.single_r_dec a {
  color: #717171;
  line-height: 20px; }

.single_r_dec a:hover {
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  color: #1fa7ec; }

.all_r_pst .media {
  margin-top: 0; }

.single_r_dec p {
  margin-bottom: 0; }

.widget-archive li {
  border-bottom: 1px solid #ebebeb;
  display: inline-block;
  padding: 10px 0;
  position: relative;
  width: 100%; }

.widget-archive span {
  float: right; }

.widget-tag a {
  float: left;
  padding: 10px;
  border: 1px solid #ececec;
  margin-right: 5px;
  margin-bottom: 5px; }

.author_dec h3 {
  margin: 0;
  color: #333333;
  font-size: 14px;
  margin-bottom: 11px; }

.author_info {
  border-bottom: 1px solid #e3e3e3;
  display: inline-block;
  padding: 22px 0;
  margin-bottom: 30px; }

.author_info > img {
  float: left;
  margin-right: 35px;
  height: 80px; }

.social_link {
  margin: 0;
  padding: 0;
  float: right;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.social_link li {
  list-style: none; }

.social_link li a i {
  color: #363636;
  float: left;
  padding: 6px; }

/*
19.Testimonial Section
=========================*/
.all-testimonial.owl-carousel .owl-item img {
  width: 100%;
  height: 100%; }

.all-testimonial .owl-controls {
  position: absolute;
  top: 40%;
  width: 100%; }

.all-testimonial .owl-controls .owl-buttons div {
  background: none;
  opacity: 1; }

.all-testimonial .owl-controls .owl-prev {
  left: -80px;
  position: absolute; }

.all-testimonial .owl-controls .owl-next {
  right: -80px;
  position: absolute; }

.all-testimonial .owl-controls .owl-prev i,
.all-testimonial .owl-controls .owl-next i {
  border: 1px solid #1fa7ec;
  border-radius: 2px;
  color: #fff;
  font-size: 30px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  width: 40px;
  background: #1fa7ec; }

.all-testimonial .owl-controls .owl-next i:hover {
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  background: #212836;
  color: #fff;
  border-color: #212836; }

.all-testimonial .owl-controls .owl-prev i:hover {
  -moz-transition: all 0.5s ease-out 0s;
       transition: all 0.5s ease-out 0s;
  background: #212836;
  color: #fff;
  border-color: #212836; }

.sngl-client {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  text-align: center;
  margin: 5px; }

.single-testimonial {
  background: #fff none repeat scroll 0 0;
  margin: 2px 10px;
  padding: 20px 10px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 20px; }

.client-comment img {
  border-radius: 2px;
  height: 200px;
  margin-bottom: 20px;
  width: 200px;
  border-bottom: 4px solid #1fa7ec; }

.client-comment h2 {
  color: #1fa7ec;
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px;
  text-transform: uppercase;
  margin-bottom: 10px; }

.client-comment h3 {
  color: #8b8b8b;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 13px; }

.client-comment p::before {
  content: "\f10d ";
  font-family: fontawesome;
  left: -8px;
  top: -8px;
  position: relative;
  color: #1fa7ec; }

.client-comment p::after {
  bottom: 4px;
  content: "\f10e";
  font-family: fontawesome;
  color: #1fa7ec;
  right: -8px;
  top: 8px;
  position: relative; }

.client-comment > ul {
  padding-bottom: 15px; }

.client-comment li {
  display: inline-block; }

.client-comment li a {
  border: 2px solid #1fa7ec;
  border-radius: 100%;
  color: #1fa7ec;
  display: inline-block;
  height: 30px;
  line-height: 26px;
  width: 30px;
  margin: 0px 5px; }

.client-comment li a:hover {
  background: #1fa7ec;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  color: #fff; }

.client-sec .owl-controls {
  display: none; }

.brder {
  border-top: 1px solid #E1E1E1;
  height: 1px;
  margin: 0 auto;
  text-align: center;
  width: 370px; }

/*
20.Page Heading Section
==========================*/
.pagehding-sec {
  /*background-image: url("../img/pg_hd.jpg");*/
  background-position: center center;
  background-repeat: no-repeat;
  padding: 50px 0;
  position: relative;
  width: 100%; }

.page-heading h1 {
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: left; }

.page-heading ul {
  text-align: right; }

.page-heading li {
  display: inline-block;
  position: relative;
  padding: 0px 14px; }

.page-heading li a {
  color: #fff; }

.page-heading li:last-child a {
  color: #1fa7ec; }

.page-heading li::before {
  border-right: 1px solid #fff;
  content: "";
  height: 60%;
  position: absolute;
  right: -2px;
  top: 5px; }

.page-heading li:last-child::before {
  display: none; }

/*
21.Team section
=================*/
.team-member {
  margin-bottom: 30px;
  overflow: hidden;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  border-radius: 2px; }

.team-member:hover .team-overlay {
  visibility: visible;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  opacity: 1;
  -moz-transform: scaleX(1);
   -ms-transform: scaleX(1);
       transform: scaleX(1); }

.team-overlay {
  position: absolute;
  visibility: hidden;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  opacity: 0;
  top: 0;
  -moz-transform: scaleX(0);
   -ms-transform: scaleX(0);
       transform: scaleX(0);
  width: 100%; }

.team-overlay > ul {
  padding-bottom: 10px; }

.team-member img {
  width: 100%;
  border-bottom: 4px solid #1fa7ec; }

.team-member h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase; }

.team-member h3 {
  font-size: 14px;
  text-transform: capitalize; }

.team-member li {
  display: inline-block;
  padding: 0 10px;
  background: #1fa7ec;
  border-radius: 2px; }

.team-member li i {
  color: #fff; }

/*
22.About Page
================*/
.about-us-sec {
  padding-bottom: 30px;
  padding-top: 50px; }

.about-img img {
  width: 100%; }

.abtdsc-sec p {
  color: #fff; }

.abt-lft h2 {
  color: #464646;
  text-transform: uppercase; }

.abt-img img {
  width: 100%; }

.about-text h3 {
  text-transform: uppercase; }

/*
23.Contact Page
============================*/
.contact-field h2,
.map-heading h2 {
  color: #464646;
  font-size: 24px;
  font-weight: bold;
  margin: 0 10px 30px;
  padding-bottom: 16px;
  position: relative;
  text-transform: uppercase; }

.map-heading h2::before {
  border-bottom: 3px solid #1fa7ec;
  bottom: 1px;
  content: "";
  height: 3px;
  position: absolute;
  width: 9%; }

.contact-info h3 {
  color: #464646;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 30px 0; }

.contact-info i {
  margin-right: 10px; }

.contact-field input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #E5E5E5;
  margin-bottom: 30px;
  padding: 10px;
  width: 100%; }

.contact-field .message-input {
  display: inline-block;
  width: 100%; }

.single-input-field textarea {
  border: 1px solid #E5E5E5;
  width: 100%;
  height: 200px;
  padding: 10px; }

.single-input-fieldsbtn input[type="submit"] {
  background: #1fa7ec none repeat scroll 0 0;
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
  border-color: #1fa7ec; }

.single-input-fieldsbtn input[type="submit"]:hover {
  background: #464646;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  border-color: #464646; }

.contact-field {
  display: inline-block;
  width: 100%; }

.single-input-field h4 {
  color: #464646;
  text-transform: capitalize;
  font-size: 14px; }

.contact-person h1 {
  color: #464646;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  padding-left: 10px; }

.contact-person h1::before {
  border-left: 3px solid #1fa7ec;
  content: "";
  height: 80%;
  left: 0;
  position: absolute;
  top: 2px;
  width: 3px; }

.single-info h2 {
  color: #464646;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-top: 30px; }

.map-sec iframe {
  width: 100%;
  height: 450px; }

/*
24.Footer Section 
======================*/
.footer-sec {
  background: #242424 none repeat scroll 0 0;
  padding: 50px 0; }

.footer-sec h2 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 20px; }

.footer-sec p {
  color: #ccc; }

.footer-wedget-one i {
  border: 1px solid #ccc;
  color: #ccc;
  float: left;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  text-align: center;
  width: 40px;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  border-radius: 2px; }

.footer-wedget-one i:hover {
  background: #1fa7ec;
  color: #fff;
  border-color: #1fa7ec;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s; }

.footer-wedget-one ul {
  margin-top: 15px; }

.footer-wedget-newsletter form {
  background: #525459 none repeat scroll 0 0;
  margin-top: 35px;
  position: relative; }

.footer-wedget-newsletter input[type="email"] {
  background: #525459 none repeat scroll 0 0;
  border: 1px solid #525459;
  color: #fff;
  padding: 10px 46px 10px 10px;
  width: 100%;
  text-transform: capitalize; }

.footer-wedget-newsletter input[type="submit"] {
  background: #1fa7ec none repeat scroll 0 0;
  border: 1px solid #1fa7ec;
  color: #fff;
  top: 0;
  padding: 10px;
  position: absolute;
  right: 0; }

.footer-wedget-three li i {
  display: inline-block;
  margin-right: 10px; }

.ft-menu ul {
  padding: 0px 20px; }

.ft-menu h2 {
  display: inline-block;
  text-align: left; }

.footer-wedget-three li {
  color: #ccc;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 10px; }

.footer-wedget-two li {
  display: inline-block; }

.footer-wedget-two li a {
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  background: #1fa7ec;
  margin: 0 2px 5px 0px;
  text-transform: uppercase;
  border-radius: 2px; }

.footer-wedget-two li a:hover {
  -moz-transition: all 0.4s ease-in-out 0s;
       transition: all 0.4s ease-in-out 0s;
  background: #464646; }

.footer-wedget-two li a i {
  margin-right: 10px; }

.footer-wedget-three input {
  background: #e9e9e9 none repeat scroll 0 0;
  border: 1px solid #e9e9e9;
  color: #ccc;
  font-size: 13px;
  margin-bottom: 10px;
  padding: 10px;
  text-transform: capitalize; }

.footer-wedget-three input[type=submit] {
  background: #1fa7ec;
  border: 1px solid #1fa7ec;
  padding: 10px 25px;
  color: #fff; }

.footer-wedget-three form {
  margin-top: 10px; }

.footer-wedget-three .single-input-field textarea {
  height: 95px;
  border-radius: 5px;
  background: #525459 none repeat scroll 0 0;
  border: 1px solid #525459;
  color: #fff; }

.footer-wedget-three input {
  background: #525459 none repeat scroll 0 0;
  border: 1px solid #525459;
  border-radius: 2px;
  color: #fff;
  font-size: 16px; }

.footer-bottom-sec {
  background: #282828 none repeat scroll 0 0;
  padding: 15px 0; }

.footer-bottom-sec p {
  color: #ccc;
  font-weight: bold;
  margin: 0;
  text-align: center; }

/* The End */
